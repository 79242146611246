import Vue from 'vue'
import $api from '@/shared/services/api'
import VueCookies from 'vue-cookies'
import { removeCookies } from '@/shared/common/functions/cookies'
Vue.use(VueCookies)
import FingerprintJS from '@fingerprintjs/fingerprintjs'

const TOKEN_ID = 'x-token-auth'
const TOKEN_DISPOSITIVO = 'token_dispositivo'

export default {
    namespaced: true,
    state: {
        token: Vue.$cookies.get(TOKEN_ID),
        tokenDispositivo: Vue.$cookies.get(TOKEN_DISPOSITIVO) || localStorage.getItem(TOKEN_DISPOSITIVO) || null,
        usuarioLogado: null,
    },
    getters: {
        pegaUsuarioLogado(state){
            return state.usuarioLogado
        },
        pegaToken(state){
            return state.token
        },
        pegaTokenDispositivo(state){
            return state.tokenDispositivo
        }
    },
    mutations: {
        atualizaToken(state, token){
            state.token = token
        },
        atualizaTokenDispositivo(state, tokenDispositivo){
            state.tokenDispositivo = tokenDispositivo
        },
        atualizaUsuarioLogado(state, usuarioLogado){
            state.usuarioLogado = usuarioLogado
        },
    },
    actions: {
        async inicializaTokenDispositivo({ state, commit }) {
            let tokenDispositivo = state.tokenDispositivo
            if (!tokenDispositivo) {
                const fp = await FingerprintJS.load()
                const result = await fp.get()
                tokenDispositivo = result.visitorId
            }
            commit('atualizaTokenDispositivo', tokenDispositivo)
        },
        salvaToken({ commit }, data){
            Vue.$cookies.set(TOKEN_ID, data.token, '365d', null, data.url_token || null)
            commit('atualizaToken', data.token)
        },
        salvaTokenDispositivo({ commit }, data){
            Vue.$cookies.set(TOKEN_DISPOSITIVO, data.token_dispositivo, '100y', null, data.url_token || null)
            localStorage.setItem(TOKEN_DISPOSITIVO, data.token_dispositivo)
            commit('atualizaTokenDispositivo', data.token_dispositivo)
        },
        async login({ dispatch, state}, dados = []){
            await  dispatch('inicializaTokenDispositivo')
            Vue.prototype.$set(dados, 'token_dispositivo', state.tokenDispositivo)
            const login = await $api.cPost(
                Vue.prototype.$urlBase
                + '/usuarios/login', 
                dados
            )
            if(login.status == 'success'){
                dispatch('salvaToken', login.data)
                dispatch('salvaTokenDispositivo', login.data)
                removeCookies()
            }
            return login
        },
        async logout({ commit, dispatch }){
            const logout = await $api.cGet(
                Vue.prototype.$urlBase
                + '/usuarios/logout'
            )
            if(logout.status == 'success'){
                dispatch('salvaToken', logout.data)
                commit('usuarioLogado', [])
                removeCookies()
            }
            return logout
        },
        async carregaUsuarioLogado({ commit }){
            const autenticacao = await $api.cGet(
                Vue.prototype.$urlBase
                + '/usuarios/verifica_autenticacao', 
                {}, 
                false
            )
            if(autenticacao.status == 'success'){
                commit('atualizaUsuarioLogado', autenticacao.data)
            }
            return autenticacao
        }
    },
}
